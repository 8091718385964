<template>
    <div class="container page">
      <div class="header">
        <van-nav-bar title="Nạp Tiền" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
      </div>
      <div class="servicelistItem">
        <div class="servicelistItemTop">
          <div class="servicelistItemText">Thông tin thanh toán</div>
        </div>
        <p>Vui lòng chuyển khoản tới thông tin sau. Tiền sẽ được cộng trong vòng 1 phút.</p>
        <div class="d-flex">
          <div class="s1">Ngân hàng</div>
          <div class="servicelistItemBottom">
            <div class="servicelistItemInfoText">
              MB Bank
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="s1">Số tài khoản</div>
          <div class="servicelistItemBottom">
            <div class="servicelistItemInfoText">
              687943535
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="s1">Chủ tài khoản</div>
          <div class="servicelistItemBottom">
            <div class="servicelistItemInfoText">
              PHAM VAN DAT
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="s1">Nội dung</div>
          <div class="servicelistItemBottom">
            <div class="servicelistItemInfoText">
              {{this.userInfo.username}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  data() {
    return {
      userInfo:{
      },
    };
  },
    methods: {
      back(){
        return window.history.back();
      },
      getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast("Ngoại tuyến");
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
      toServicePage(){
        this.$router.push("ServicePage");
      }
    },  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = "Đăng nhập / Đăng ký";
      this.userInfo.ip = "Đăng nhập để tận hưởng nhiều dịch vụ hơn！";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  .d-flex {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .d-flex > .s1 {
    width: 120px;
    font-weight: bold;
  }
  .d-flex > .servicelistItemBottom {
    flex: 1;
  }
  .servicelistItem{
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 30px 20px;
    border-radius: 20px;
    justify-content: space-between;
    background: #fff;
  }
  .servicelistItem .servicelistItemTop{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;  
  }
  .servicelistItem .servicelistItemTop .servicelistItemImage{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .servicelistItem .servicelistItemTop .servicelistItemText{
    margin-left: 10px;
    font-size: 25px;
    font-weight: 700;
    color: #000;
    flex: 1;
  }
  .servicelistItem .servicelistItemTop .servicelistItemBtn{
    display: flex;
    width: 80px;
    height: 35px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        270deg,#ef42cd,#8774d3);
  }
  .servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText{
    color: #fff;
    font-size: 13px;
  }
  .servicelistItem .servicelistItemBottom{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #f2f2f5;
    border-radius: 10px;
    color: #979799;
  }
  .servicelistItem .servicelistItemBottom .servicelistItemInfoText{
    font-size: 16px;
  }
  </style>
  