<template>
	<van-tabbar v-if="show" v-model="active" active-color="#8774d3" :border="true" inactive-color="#979799">
		<van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
			<span>{{ k.title }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2" />
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" v-show="key === 2" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Home',
					title: 'Trang chủ',
					icon: {
						active: '/img/footer/ic-home-act.jpg',
						noactive: '/img/footer/ic-home.jpg'
					}
				},
				{
					router: '/Game',
					title: 'Sảnh bình chọn',
					icon: {
						active: '/img/footer/ic-clock-act.jpg',
						noactive: '/img/footer/ic-clock.jpg'
					}
				},
				{
					router: '/Choose',
					title: '',
					icon: {
						active: '/img/footer/beauty.png',
						noactive: '/img/footer/beauty.png'
					}
				},
				{
					router: '/Video',
					title: 'Video',
					icon: {
						active: '/img/footer/ic-film-act.jpg',
						noactive: '/img/footer/ic-film.jpg'
					}
				},
				{
					router: '/Mine',
					title: 'Hồ sơ',
					icon: {
						active: '/img/footer/ic-user-act.jpg',
						noactive: '/img/footer/ic-user.jpg'
					}
				}
			]
		};
	},
	methods: {},
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'game') {
				this.active = 1;
				this.show = true;
			} else if (to.name == 'choose') {
				this.active = 2;
				this.show = true;
			} else if (to.name == 'video') {
				this.active = 3;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 4;
				this.show = true;
			} else {
				this.show = false;
			}
		}
	},
	created() {
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'game') {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == 'choose') {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == 'video') {
			this.active = 3;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 4;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 75px;
@tabbar-img: 45px;
.van-tabbar {
	height: @tabbar-height;
	max-width: 600px;
	left: 50%;
	transform: translateX(-50%);
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
}
.van-tabbar-item {
	font-size: 15px;
}
.tui {
	width: 85px;
	height: 85px!important;
	margin-top: -38.333px;
	background-color: white;
	border-radius: 50%;
	border: 5px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}
@media (max-width:500px){
	.van-tabbar-item__icon img {
		height:33px;
		width: auto;
	}
	.van-tabbar-item {
		font-size: 12px;
	}
	.van-tabbar {
		height: 55px;
	}
	.tui {
		height: 70px!important;
		width: 70px!important;
		margin-top: -20px
	}
}
</style>
